import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import './index.css';

//react native/PWA support
// import { AppRegistry } from "react-native";
// const appName = "CampusLens";

// AppRegistry.registerComponent(appName, () => App);
// AppRegistry.runApplication(appName, {
//   initialProps: {},
//   rootTag: document.getElementById("root"),
// });
//service worker
import registerServiceWorker from "./serviceWorkerRegistration";
registerServiceWorker();

//end react native support

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);
